import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ElementUI from 'element-ui';

import Swal from "sweetalert2";
window.Toast = function(type, msg) {
  Swal.fire({
    toast: true,
    icon: type,
    title: msg,
    position: "top-end",
    showConfirmButton: false,
    timer: 10000,
    background: "#38485E",
    // html: "<span style='color: #fff; font-size: 20px; margin-left: 5px;'>" + msg + "</span>",
    showCancelButton: false,
    timerProgressBar: false
  });
};
// window.Toast(type, msg);

import ApiService from "@/core/services/api.service";
window.ApiService = ApiService;

// import MockService from "@/core/mock/mock.service";
import { VERIFY_AUTH, LOGOUT } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import 'element-ui/lib/theme-chalk/index.css';

// API service init
ApiService.init();

Vue.axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response.status === 401) {
    store
      .dispatch(LOGOUT)
      .then(() => router.replace({ name: "login" }));
    return Promise.reject("未登录或登录超时，请重新登录！");
  }
  return Promise.reject(error);
});

// Remove this to disable mock API
// MockService.init();

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH, { to, from })]).then(next);

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

 
//获取指定class的父节点
function getParents(element, className) {
  //dom.getAttribute('class')==dom.className，两者等价
  var returnParentElement = null;

  function getpNode(element, className) {
    if (!element && !element.parentNode) {
      return;
    }

    //创建父级节点的类数组
    let pNode = element.parentNode;
    let pClassList = pNode.getAttribute('class');

    if (!pClassList) { //如果未找到类名数组，就是父类无类名，则再次递归
      getpNode(pNode, className);

    } else if (pClassList && pClassList.indexOf(className) < 0) { //如果父类的类名中没有预期类名，则再次递归
      getpNode(pNode, className);

    } else if (pClassList && pClassList.indexOf(className) > -1) {
      returnParentElement = pNode;
    }
  }

  getpNode(element, className);
  return returnParentElement;
}

Vue.directive("lcx-drag", {
  inserted: function(dragDom) {
    // const el = appEl.querySelector(".v-dialog__content");
    // const dragDom = el.querySelector(".lcx-drag-header");

    const el = getParents(dragDom, 'v-dialog__content');
    if (el == null) {
      return;
    }

    dragDom.style.cursor = "move";

    dragDom.onmousedown = function (e) {
      e.stopPropagation();
      var disX = e.pageX - el.offsetLeft;
      var disY = e.pageY - el.offsetTop;
      document.onmousemove = function (e) {
        el.style.left = e.pageX - disX + "px";
        el.style.top = e.pageY - disY + "px";
      };
      document.onmouseup = function () {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    };
  }
});

Vue.use(ElementUI);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  ApiService,
  render: h => h(App)
}).$mount("#app");
