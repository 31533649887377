const ID_TOKEN_KEY = "id_token";

const ORGZ_CODE_KEY = "user_orgz_code";

const RES_LIST_KEY = "resource_list";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem(ORGZ_CODE_KEY);
};

export const getOrgzCode = () => {
  return window.localStorage.getItem(ORGZ_CODE_KEY);
};

export const saveOrgzCode = orgzCode => {
  window.localStorage.setItem(ORGZ_CODE_KEY, orgzCode);
};

export const getResList = () => {
  return JSON.parse(window.localStorage.getItem(RES_LIST_KEY));
};

export const saveResList = resList => {
  window.localStorage.setItem(RES_LIST_KEY, JSON.stringify(resList));
};

export default { getToken, saveToken, destroyToken, getOrgzCode, saveOrgzCode, getResList, saveResList };
