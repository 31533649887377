<template>
  <router-view></router-view>
</template>

<style lang="scss">

// 不知道为啥在单页面引用可以，这里却不行，不过也无所谓了，js里加了样式
// @import '~@sweetalert2/themes/Borderless/Borderless.scss';

// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~animate.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";

// Main demo style scss
@import "assets/sass/style.vue";

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/


// 左上角消息提示toast
.swal2-popup .swal2-title {
    color: #ffffff !important;
}
.swal2-popup.swal2-toast .swal2-title {
    font-size: 1.2em !important;
}

//表格样式
.table thead tr {
  background-color: #38485e;
  color: #fff;
}
.table th,
.table td {
  border-bottom: 2px solid #ebedf3 !important;
}

// 翻页按钮 lcx
.pagination-lg .page-link {
    padding: 0.5rem 1.3rem !important;
}
.v-application--wrap {
  min-height: 0 !important;
}

.v-card__actions {
  box-shadow: darkgrey 10px 10px 30px 5px;
}

::-webkit-scrollbar {
  display: none;
}
#kt_wrapper{
  position: absolute;
  width: 100%;
  height: 100%;
}
#kt_footer{
  position: relative;
  bottom: 0px;
}
</style>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";

export default {
  name: "MetronicVue",
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);

  },
  created() {
    //在页面刷新时将vuex里的信息保存到localStorage里
    window.addEventListener("beforeunload",()=>{
    localStorage.setItem("messageStore", JSON.stringify(this.$store.state))
    })
    
    //在页面加载时读取localStorage里的状态信息
    localStorage.getItem("messageStore") && this.$store.replaceState(Object.assign(this.$store.state,JSON.parse(localStorage.getItem("messageStore"))));
  }
};
</script>
