import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    // Vue.axios.defaults.baseURL = "http://localhost:82"; x

    if (process.env.NODE_ENV === "production") {
      // Vue.axios.defaults.basexURL = "http://192.168.1.16:82";
      // Vue.axios.defaults.baseURL = "http://192.168.1.24:81";
      Vue.axios.defaults.basexURL = ""
    } else {
      // Vue.axios.defaults.baseURL = "/api";
      Vue.axios.defaults.baseURL = "http://192.168.31.124:8024";
      // Vue.axios.defaults.baseURL = "http://127.0.0.1:8024";
    }

    // process.env.NODE_ENV === "production" ? "http://192.168.1.16:82" : ""

    // switch (process.env.NODE_ENV) {
    //   case 'dev':
    //       baseUrl = "http://localhost:8088/"  //开发环境url
    //       break
    //   case 'serve':
    //       baseUrl = "http://localhost:8089/"   //生产环境url
    //       break
    // }
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `${JwtService.getToken()}`;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  get(resource) {
    ApiService.setHeader();
    return new Promise(resolve => {
      Vue.axios.get(`${resource}`)
        .then(({ data }) => {
          if (!data.success) {
            window.Toast("error", data.msg);
            return;
          }
          resolve(data);
        })
        .catch(error => {
          window.Toast("error", error);
        });
    });
  },  

  post(resource, params) {
    ApiService.setHeader();
    return new Promise(resolve => {
      Vue.axios.post(`${resource}`, params)
        .then(({ data }) => {
          if (!data.success) {
            window.Toast("error", data.msg);
            return;
          }
          resolve(data);
        })
        .catch(error => {
          window.Toast("error", error);
        });
    });
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
  
  // ,

  // makeToast(variant = null, info_) { // primary secondary danger warning success info 
      
  //   var title_ = "提示";

  //   if (variant == "danger") {
  //     title_ = "错误";
  //   } else if (variant == "warning") {
  //     title_ = "警告";
  //   }

  //   // this.$bvToast.toast(info_, {
  //   window.vue.$bvToast.toast(info_, {
  //      title: title_,
  //      variant: variant,
  //      solid: true
  //    });
  // }

};

export default ApiService;
