import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

//Uncaught (in promise) Error: Navigation cancelled from "/account-list" to "/login" with a new navigation.
//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;
//push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
//replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/order-list",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/account-list",
          name: "account-list",
          component: () => import("@/view/pages/account/account-list.vue")
        },
        {
          path: "/role-list",
          name: "role-list",
          component: () => import("@/view/pages/account/role-list.vue")
        },
        {
          path: "/resource-list",
          name: "resource-list",
          component: () => import("@/view/pages/account/resource-list.vue")
        },
        // /vegetable/order
        {
          path: "/order-list",
          name: "order-list",
          component: () => import("@/view/pages/vegetable/order-list.vue")
        },
        {
          path: "/order-import",
          name: "order-import",
          component: () => import("@/view/pages/vegetable/order-import.vue")
        },
        // supplier-report
        // {
        //   path: "/supplier-report",
        //   name: "supplier-report",
        //   component: () => import("@/view/pages/vegetable/supplier-report.vue")
        // },
        // {
        //   path: "/customer-report",
        //   name: "customer-report",
        //   component: () => import("@/view/pages/vegetable/customer-report.vue")
        // },
        // {
        //   path: "/supplier-customer-report",
        //   name: "supplier-customer-report",
        //   component: () => import("@/view/pages/vegetable/supplier-customer-report.vue")
        // },
        // supplier-vegetable
        {
          path: "/supplier-vegetable",
          name: "supplier-vegetable",
          component: () => import("@/view/pages/vegetable/supplier-vegetable.vue")
        },
        {
          path: "/supplier-list",
          name: "supplier-list",
          component: () => import("@/view/pages/vegetable/supplier-list.vue")
        },
        // {
        //   path: "/vegetable-list",
        //   name: "vegetable-list",
        //   component: () => import("@/view/pages/vegetable/vegetable-list.vue")
        // },
        {
          path: "/customer-list",
          name: "customer-list",
          component: () => import("@/view/pages/vegetable/customer-list.vue")
        },
        {
          path: "/alarm-list",
          name: "alarm-list",
          component: () => import("@/view/pages/alarm/alarm-list.vue")
        },
        {
          path: "/builder",
          name: "builder",
          component: () => import("@/view/pages/Builder.vue")
        },

        // VueBootstrap.vue
        {
          path: "/vue-bootstrap",
          name: "vue-bootstrap",
          component: () =>
            import("@/view/pages/vue-bootstrap/VueBootstrap.vue"),
          children: [
            {
              path: "alert",
              name: "vue-bootstrap-alert",
              component: () => import("@/view/pages/vue-bootstrap/Alert.vue")
            },
            {
              path: "tabs",
              name: "vue-bootstrap-tabs",
              component: () => import("@/view/pages/vue-bootstrap/Tabs.vue")
            }
          ]
        },

        // Vuetify.vue
        {
          path: "/",
          name: "vuetify",
          component: () => import("@/view/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "/vegetable-list",
              name: "vegetable-list",
              component: () => import("@/view/pages/vegetable/vegetable-list.vue")
            },
            {
              path: "/supplier-report",
              name: "supplier-report",
              component: () => import("@/view/pages/vegetable/supplier-report.vue")
            },
            {
              path: "/customer-report",
              name: "customer-report",
              component: () => import("@/view/pages/vegetable/customer-report.vue")
            },
            {
              path: "/supplier-customer-report",
              name: "supplier-customer-report",
              component: () => import("@/view/pages/vegetable/supplier-customer-report.vue")
            },
            {
              path: "alerts",
              name: "vuetify-alerts",
              component: () => import("@/view/pages/vuetify/Alerts.vue")
            },
            {
              path: "treeview",
              name: "vuetify-treeview",
              component: () => import("@/view/pages/vuetify/Treeview.vue")
            }
          ]
        },

        // Wizard.vue
        {
          path: "/custom-wizard",
          name: "wizard",
          component: () => import("@/view/pages/wizard/Wizard.vue"),
          children: [
            {
              path: "wizard-1",
              name: "wizard-1",
              component: () => import("@/view/pages/wizard/Wizard-1.vue")
            },
            {
              path: "wizard-2",
              name: "wizard-2",
              component: () => import("@/view/pages/wizard/Wizard-2.vue")
            },
            {
              path: "wizard-3",
              name: "wizard-3",
              component: () => import("@/view/pages/wizard/Wizard-3.vue")
            },
            {
              path: "wizard-4",
              name: "wizard-4",
              component: () => import("@/view/pages/wizard/Wizard-4.vue")
            }
          ]
        },

        // Plugins.vue
        {
          path: "/custom-plugins",
          name: "plugins",
          component: () => import("@/view/pages/plugins/Plugins.vue"),
          children: [
            {
              path: "cropper",
              name: "cropper",
              component: () => import("@/view/pages/plugins/Cropper.vue")
            },
            {
              path: "treeselect",
              name: "treeselect",
              component: () => import("@/view/pages/plugins/Treeselect.vue")
            }
          ]
        },

        // Profile.vue
        {
          path: "/profile",
          name: "profile",
          component: () => import("@/view/pages/profile/Profile.vue"),
          children: [
            {
              path: "profile-1",
              name: "profile-1",
              component: () => import("@/view/pages/profile/Profile-1.vue")
            },
            {
              path: "profile-2",
              name: "profile-2",
              component: () => import("@/view/pages/profile/Profile-2.vue")
            },
            {
              path: "profile-3",
              name: "profile-3",
              component: () => import("@/view/pages/profile/Profile-3.vue")
            },
            {
              path: "profile-4",
              name: "profile-4",
              component: () => import("@/view/pages/profile/Profile-4.vue")
            }
          ]
        },
      ]
    },

    // Error.vue
    {
      path: "/custom-error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue")
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue")
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue")
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue")
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue")
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue")
        }
      ]
    },
    // Login-1
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_v2/Login-2"),
      //component: () => import("@/view/pages/auth/login_pages/Login-1"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_v2/Login-2")
          // component: () => import("@/view/pages/auth/login_pages/Login-1")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/login_pages/Login-1")
        }
      ]
    },
    // 404
    {
      path: "*",
      redirect: "/404"
    },

    // 404 Error-1.vue
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
