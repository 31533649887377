// import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import router from "@/router";

import { Auth } from "@/assets/js/pages/account/account.js";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise(resolve => {
      Auth.login(credentials).then(({ data }) => {
        context.commit(SET_AUTH, data);
        resolve(data);
      });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise(resolve => {
      Auth.login(credentials).then(({ data }) => {
        context.commit(SET_AUTH, data);
        resolve(data);
      });
    });
  },
  [VERIFY_AUTH](context, data) {
    if (data.to == "/login" || data.to == "/404") {
      // console.log("放行： " + data.to.path);
      return;
    }
    if (JwtService.getToken()) {
      Auth.verify(data).then(({ data }) => {
        if (data == false) {
          router.push({ name: "404" });
        }
      });
    } else {
      context.commit(PURGE_AUTH);
      // router.push({ name: "login" }); // main js 中已经处理 这里再加的话 会无限循环跳转登录页
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    return Auth.setPwd(payload).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    // state.user_personal_info = user;
    state.errors = {};
    JwtService.saveResList(state.user.resList);
    JwtService.saveOrgzCode(state.user.orgzCode);
    JwtService.saveToken(state.user.token);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
